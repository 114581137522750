import { List } from 'immutable';
import React from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { History } from "history"
import '../App.css';
import { Calculator } from '../components/calculator-v2';
import { getMyQuestions, Question, submitQuestion } from '../services/questions';
import { APIResult, Routing } from '../types/generics';


type HomeProps = {
  history: History
}
type AppState = {
  currentQuestion: string
  otherQuestions: APIResult<List<Question>>
  routeTo: Routing
  questionsIdx: number
  answersIdx: number
}

function HomePage() {
  return (<div><Home history={useHistory()}/></div>)
}

class Home extends React.Component<HomeProps, AppState> {
  constructor(props: HomeProps) {
    super(props)
    this.state = {
      currentQuestion: "",
      routeTo: { routing: false },
      otherQuestions: { result: "waiting"},
      questionsIdx: -1,
      answersIdx: -1
    }
  }

  componentDidMount() {
    getMyQuestions().then(apiResult => {
      if(apiResult.result === "success") {
        this.setState({otherQuestions: apiResult})
      }
    })
  }

  submitHandler() {
    if (this.state.currentQuestion !== "") {
      submitQuestion({ text: this.state.currentQuestion }).then(
        res => {
          if (res.result === "success") {
            this.setState(s => ({ ...this.state, routeTo: { routing: true, to: `/questions/${res.value.id}` } }))
          }
        }
      )
    }

  }

  onModeChange(mode: "question" | "answer" | "view") {
    switch (mode) {
      case "answer":
        this.setState({ routeTo: { routing: true, to: "/questions" } })
        break;
      case "question":
        // this.setState({ routeTo: { routing: true, to: "/" } })
        break;
      case "view":
        break;
    }
  }
  render() {
    if (this.state.routeTo.routing) {
      return <Redirect to={this.state.routeTo.to} />
    }
    const firstOtherQuestion = this.state.otherQuestions.result === "success" ?  this.state.otherQuestions.value.first(null) : null

    const onNextQuestion = firstOtherQuestion !== null ? () => 
      this.setState({routeTo: {routing: true, to: `/questions/${firstOtherQuestion.id}`}}) :  undefined
    
    return (
      <Calculator
        onModeChange={(mode: "question" | "answer" | "view") => this.onModeChange(mode)}
        mode="question"
        currentQuestion={this.state.currentQuestion}
        questionHeader="your question"
        answerHeader="press '=' to ask the internet"
        onNextQuestion={onNextQuestion}
        onPrevQuestion={undefined}
        onNextAnswer={undefined}
        onPrevAnswer={undefined}
        currentAnswer="^_^"
        onSubmit={() => this.submitHandler()}
        editorValue={this.state.currentQuestion}
        updateValue={(v, cb) => this.setState({ ...this.state, currentQuestion: v }, cb)}
      />
    )
  }
}

// function Home() {
//   return (
//     <div className="Home">
//       <QuestionsList/>
//       <SubmitQuestion/>
//     </div>
//   );
// }


export default HomePage;
