import * as React from "react"
import { Button } from "./button"
import { History} from "history"
import { useHistory } from "react-router-dom"

type KeyboardProps = {
  changeBuffer: (value: string, flush: boolean, callback?: () => void) => void
  flush: () => void
  changeMode: (mode: "question" | "answer") => void
  submit: () => void
  clear: () => void
  backspace: () => void
  currentBuffer: string
  history: History
}
type KeyboardState = {}

export function Keyboard(props: Omit<KeyboardProps, "history">) {
  const history = useHistory();
  return <KeyboardHistory {...{...props, history}}/>;
}

export class KeyboardHistory extends React.Component<KeyboardProps, KeyboardState> {
  constructor(props: KeyboardProps) {
    super(props)
    this.state = {}
  }

  render() {
    return <div id="keyboard">
      <div className="button-row">
          <div className="button green" data-button="questions" data-action="questions" onClick={e => this.props.changeMode("question")}>
            <div className="value">
              <svg className="svg-icon size-m"><use xlinkHref="#icon-home"/></svg>
            </div>
          </div>
          <div className="button" data-button="answers" data-action="answers" onClick={e => this.props.changeMode("answer")}>
            <div className="value">
              <svg className="svg-icon"><use xlinkHref="#icon-speech"/></svg>
            </div>
          </div>
          <div className="button" data-button="settings" data-action="settings" onClick={e => this.props.history.push("/settings")}>
            <div className="value">
              <svg className="svg-icon"><use xlinkHref="#icon-settings"/></svg>
            </div>
          </div>
          <div className="button clear red" data-button="clear" data-action="clear" onClick={e => this.props.backspace()}>
            <div className="value">
              <svg className="svg-icon"><use xlinkHref="#icon-backspace"/></svg>
            </div>
          </div>
      </div>
      <div className="button-row">
        <Button currentBuffer={this.props.currentBuffer} flush={this.props.flush} primaryValue="7" secondaryValue="PQRS" type="operand"  setBuffer={this.props.changeBuffer} />
        <Button currentBuffer={this.props.currentBuffer} flush={this.props.flush} primaryValue="8" secondaryValue="TUV" type="operand"  setBuffer={this.props.changeBuffer} />
        <Button currentBuffer={this.props.currentBuffer} flush={this.props.flush} primaryValue="9" secondaryValue="WXYZ" type="operand"  setBuffer={this.props.changeBuffer} />
        <Button currentBuffer={this.props.currentBuffer} flush={this.props.flush} primaryValue="÷" type="operator" extraClasses="operator-divide" setBuffer={_ => this.props.changeBuffer("/", true)} />
      </div>
      <div className="button-row">
        <Button currentBuffer={this.props.currentBuffer} flush={this.props.flush} primaryValue="4" secondaryValue="GHI" type="operand"  setBuffer={this.props.changeBuffer} />
        <Button currentBuffer={this.props.currentBuffer} flush={this.props.flush} primaryValue="5" secondaryValue="JKL" type="operand"  setBuffer={this.props.changeBuffer} />
        <Button currentBuffer={this.props.currentBuffer} flush={this.props.flush} primaryValue="6" secondaryValue="MNO" type="operand"  setBuffer={this.props.changeBuffer} />
        <Button currentBuffer={this.props.currentBuffer} flush={this.props.flush} primaryValue="×" type="operator" extraClasses="operator-multiply" setBuffer={_ => this.props.changeBuffer("*", true)} />
      </div>
      <div className="button-row">
        <Button currentBuffer={this.props.currentBuffer} flush={this.props.flush} primaryValue="1" type="operand"  setBuffer={this.props.changeBuffer} />
        <Button currentBuffer={this.props.currentBuffer} flush={this.props.flush} primaryValue="2" secondaryValue="ABC" type="operand"  setBuffer={this.props.changeBuffer} />
        <Button currentBuffer={this.props.currentBuffer} flush={this.props.flush} primaryValue="3" secondaryValue="DEF" type="operand" setBuffer={this.props.changeBuffer} />
        <Button currentBuffer={this.props.currentBuffer} flush={this.props.flush} primaryValue="-" type="operator" extraClasses="operator-minus" setBuffer={_ => this.props.changeBuffer("-", true)} />
      </div>
      <div className="button-row">
        <Button currentBuffer={this.props.currentBuffer} flush={this.props.flush} primaryValue="0" type="operand" setBuffer={this.props.changeBuffer} />
        <Button currentBuffer={this.props.currentBuffer} flush={this.props.flush} primaryValue="." type="decimal" setBuffer={_ => this.props.changeBuffer(".", true)} />
        <Button currentBuffer={this.props.currentBuffer} flush={this.props.flush} primaryValue="=" type="equals" setBuffer={(v) => this.props.submit()} extraClasses="cyan" />
        <Button currentBuffer={this.props.currentBuffer} flush={this.props.flush} primaryValue="+" type="operator" extraClasses="operator-plus" setBuffer={_ => this.props.changeBuffer("+", true)} />
      </div>
    </div>
  }
}
