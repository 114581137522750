export interface UserData {
  name: string;
}

export async function getMyIdentity() : Promise<UserData> {
  let identityResult = await fetch("/api/my-identity");
  let identityJson: {data: UserData} = await identityResult.json();
  return identityJson.data;
}

export async function setUserName(userName: string): Promise<void> {
  let identityResult = await fetch("/api/my-identity", {
    method: "POST",
    headers: {
      "content-type": "application/json"
    },
    body: JSON.stringify({
      name: userName
    }),
  });
}
