import React from "react";
import { Calculator } from "../components/calculator-v2";
import { useHistory } from "react-router-dom";
import { History } from "history";

export default function LoadingPage() {
  const history = useHistory();
  return <LoadingPageHistory history={history}/>
}

class LoadingPageHistory extends React.Component<{history: History}, {}> {

  render() {
    return (
      <Calculator
        onModeChange={(mode: "question" | "answer" | "view") => {
          this.onModeChange(mode)
        }}
        mode={"view"}
        questionHeader="loading"
        currentQuestion="-_^"
        updateValue={() => undefined}
        editorValue=""
        answerHeader=""
        currentAnswer="..."
        onSubmit={() => undefined}
      />
    );
  }


  onModeChange(mode: "question" | "answer" | "view") {
    switch (mode){
      case "answer":
        this.props.history.push("/questions")
        break;
      case "question":
        this.props.history.push("/")
        break;
      case "view":
        break;
    }
  }

}
