import * as React from "react"

export type ButtonProps = {
  primaryValue: string
  secondaryValue?: string
  type: "operand" | "operator" | "clear" | "decimal" | "equals"
  extraClasses?: string
  setBuffer?: (value: string, flush: boolean, callback?: () => void) => void
  flush?: () => void
  currentBuffer: string
}

export type ButtonState = {
  keyIndex: number
  pressed: boolean
}

export class Button extends React.Component<ButtonProps, ButtonState> {
  keys: string[] = []
  timer: number = 0
  constructor(props: ButtonProps) {
    super(props)
    this.state = { keyIndex: 0, pressed: false }
    this.keys = [this.props.primaryValue]
    if (this.props.secondaryValue) {
      this.keys.push(...this.props.secondaryValue!.split(""))
    }

  }

  componentDidUpdate(prevProps: ButtonProps) {
    if (this.props.currentBuffer !== prevProps.currentBuffer && this.props.currentBuffer === "_") this.setState(s => ({...s, keyIndex:0}))
  }

  render() {
    return <div className={`button ${this.props.type} ${this.props.extraClasses} ${this.state.pressed ? "pressed" : ""}`}
      onMouseDown={e => this.setState({ ...this.state, pressed: true })}
      onMouseUp={e => this.setState({ ...this.state, pressed: false })}
      onClick={e => { this.handleClickEvent() }}>
      {this.props.type === "operand" ? <div className="t9">{this.props.secondaryValue}</div> : <></>}
      <div className="value">{this.props.primaryValue}</div>
    </div>
  }

  handleClickEvent() {
    if (this.props.type === "operator") {
      if (this.props.setBuffer) this.props.setBuffer(` ${this.props.primaryValue} `, true)
    } else {
      if (!this.keys.includes(this.props.currentBuffer) && this.props.currentBuffer !== "_") {
        this.props.flush!()
        this.setState(s => ({ ...this.state, keyIndex: 0 }), () => {
          this.props.setBuffer!(this.keys[this.state.keyIndex], false, () => {
            if (this.state.keyIndex === this.keys.length - 1) {
              this.setState(s => ({ keyIndex: 0 }))
            } else {
              this.setState(s => ({ keyIndex: this.state.keyIndex + 1 }))
            }

          })

        })
      } else {
        if (this.props.setBuffer) this.props.setBuffer(this.keys[this.state.keyIndex], false, () => {
          if (this.state.keyIndex === this.keys.length - 1) {
            this.setState(s => ({ keyIndex: 0 }))
          } else {
            this.setState(s => ({ keyIndex: this.state.keyIndex + 1 }))
          }

        })

      }
    }
  }
}
