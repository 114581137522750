import * as React from "react"
import { Keyboard } from "./calculator/keyboard"

type CalculatorProps = {
  editorValue: string
  currentQuestion: string
  questionHeader: string
  answerHeader: string
  currentAnswer: string
  updateValue: (value: string, callback?: () => void) => void
  onPrevQuestion?: () => void
  onNextQuestion?: () => void
  onPrevAnswer?: () => void
  onNextAnswer?: () => void
  onSubmit: () => void
  onModeChange: (mode: "question" | "answer" | "view") => void
  mode: "question" | "answer" | "view"
}
type CalculatorState = {
  buffer: string
}

export class Calculator extends React.Component<CalculatorProps, CalculatorState> {
  constructor(props: CalculatorProps) {
    super(props)
    this.state = { 
      buffer: "_", 
    }
  }

  render() {
    return (
      <div id="calculater">
        <div id="top">
          <div id="title">CALC-U-LATER</div>
          <div id="solar">
            <div className="solar-cell"></div>
            <div className="solar-cell"></div>
            <div className="solar-cell"></div>
            <div className="solar-cell"></div>
          </div>
        </div>

        <div className="display-box top">
          <div className="header">QUESTION</div>
          <div className="display">
            <div className="display-set">
              <div className="display-name">{this.props.questionHeader}</div>
              <div className="display-chars">
                {this.renderPrevQuestion()}
                <div className="value">{this.props.currentQuestion}{this.props.mode === "question" ? this.state.buffer : ""}</div>
                <div className="bg">8888888888</div>
                {this.renderNextQuestion()}
              </div>
            </div>
          </div>
        </div>

        <div className="display-box bottom">
          <div className="header">ANSWER</div>
          <div className="display">
            <div className="display-set">
              <div className="display-name">{this.props.answerHeader}</div>
              <div className="display-chars">
                {this.renderPrevAnswer()}
                <div className="value">{this.props.currentAnswer}{this.props.mode === "answer" ? this.state.buffer : ""}</div>
                <div className="bg">88888888888</div>
                {this.renderNextAnswer()}
              </div>
            </div>
          </div>
        </div>
        <Keyboard
          changeMode={this.props.onModeChange}
          currentBuffer={this.state.buffer}
          clear={() => this.props.updateValue("", () => null)}
          backspace={() => this.props.updateValue(this.props.editorValue.slice(0, -1), () => null)}
          submit={() => this.props.onSubmit()}
          flush={() =>
            this.props.updateValue(this.props.editorValue + this.state.buffer, () => this.setState(s => ({ ...this.state, buffer: "_" })))
          }
          changeBuffer={(value: string, flush: boolean, callback?: () => void) => {
            this.setState(s => ({ ...s, buffer: value }), () => {
              if (flush) {
                this.props.updateValue(this.props.editorValue + value, () => this.setState(s => ({ ...this.state, buffer: "_" })))
              } else {
                clearTimeout((window as any).bufferTimeout);
                (window as any).bufferTimeout = setTimeout(() => {
                  this.props.updateValue(this.props.editorValue + value, () => this.setState(s => ({ ...this.state, buffer: "_" })))
                }, 750)
              }
              if (callback) callback()
            })
          }}
        />

      </div>
    )
  }

  private renderPrevQuestion() {
    if (this.props.onPrevQuestion) {
      return <div className="arrow-left" onClick={this.props.onPrevQuestion}>&lt;</div>
    } else {
      return <div className="arrow-left hidden">&lt;</div>
    }
  }

  private renderNextQuestion() {
    if (this.props.onNextQuestion) {
      return <div className="arrow-right" onClick={this.props.onNextQuestion}>&gt;</div>
    } else {
      return <div className="arrow-right hidden">&gt;</div>
    }
  }

  private renderPrevAnswer() {
    if (this.props.onPrevAnswer) {
      return <div className="arrow-left" onClick={this.props.onPrevAnswer}>&lt;</div>
    } else {
      return <div className="arrow-left hidden">&lt;</div>
    }
  }

  private renderNextAnswer() {
    if (this.props.onNextAnswer) {
      return <div className="arrow-right" onClick={this.props.onNextAnswer}>&gt;</div>
    } else {
      return <div className="arrow-right hidden">&gt;</div>
    }
  }
}
