import { List } from 'immutable';
import React from 'react';
import { useHistory, useParams } from "react-router-dom";
import { History } from "history"
import '../App.css';
import { Calculator } from '../components/calculator-v2';
import { Answer, getMyQuestions, getQuestionById, Question, QuestionWithAnswer } from '../services/questions';
import { APIResult } from '../types/generics';
import LoadingPage from "./Loading";
type QuestionModeState = {
  question: APIResult<QuestionWithAnswer>
  questions: APIResult<List<Question>>
  questionsIdx?: number
  answersIdx?: number
}

type QuestionModeProps = {
  questionId: string
  history: History
}

function QuestionPage() {
  const { questionId } = useParams() as any
  return (<div>
    <QuestionMode
      questionId={questionId}
      history={useHistory()}
    />
  </div>)
}
export class QuestionMode extends React.Component<QuestionModeProps, QuestionModeState> {

  constructor(props: QuestionModeProps) {
    super(props)
    this.state = {
      question: { result: "waiting" },
      questions: { result: "waiting" }
    }
  }

  async componentDidUpdate(prevProps: QuestionModeProps, prevState: QuestionModeState) {
    if (this.state.questionsIdx !== prevState.questionsIdx) {
      if (this.state.questions.result === "success") {
        let currentQuestion = this.state.questions.value.get(this.state.questionsIdx!)
        let questionResult = await getQuestionById(currentQuestion!.id)
        // Don't add questionsIdx here to the state, something buggy is going on but it works now.
        this.setState({ question: questionResult, answersIdx: 0 })
      }
    }

  }

  async componentDidMount() {
      let myQuestions = await getMyQuestions()
      this.setState({
        // question: questionResult,
        questions: myQuestions,
      })
  }

  render() {
    const { question, questions, questionsIdx, answersIdx } = this.state;
    if (question) {
      switch (questions.result) {
        case "waiting":
          return this.renderWaiting();
        case "failure":
          return this.renderFailure(questions.error);
        case "success":
          if (question.result === "success") {
            return this.renderCalculator(questions.value, questionsIdx!, question.value.answers, answersIdx!,);
          } else {
            return this.renderCalculator(questions.value, questionsIdx!, List(), 0)
          }
      }
    }
  }

  renderWaiting() {
    return (
      <LoadingPage />
    );
  }

  renderFailure(error: string) {
    return (
      <div>
        Failure: {error}
      </div>
    );
  }

  renderCalculator(questions: List<Question>, questionsIdx: number, answers: List<Answer>, answersIdx: number) {
    let currentQuestion = "";
    const question = questions.get(questionsIdx)
    const answer = answers.get(answersIdx)
    if (question) {
      currentQuestion = question.data.text;
    } else {
      // TODO bad idea to this.setState in render!
      this.setState({ questionsIdx: 0 });
      return <div>temp error</div>;
    }

    const prevQuestion = questionsIdx > 0 ?
      () => {
        this.setState({ questionsIdx: questionsIdx - 1 });
      } : () => this.props.history.push("/")

    const nextQuestion = questionsIdx < questions.size - 1 ?
      () => {
        this.setState({ questionsIdx: questionsIdx + 1 });
      } : undefined;

    const prevAnswer = answersIdx > 0 ?
      () => {
        this.setState({ answersIdx: answersIdx - 1 })
      } : undefined
    const nextAnswer = answersIdx < answers.size - 1 ?
      () => {
        this.setState({ answersIdx: answersIdx + 1 })
      } : undefined


    const answerHeader = answer === undefined ? `no answers yet` : answer.name
    const answerText = answer === undefined ? `-_-` : answer.data.text
    return (
      <Calculator
        editorValue=""
        mode="view"
        currentQuestion={currentQuestion}
        questionHeader={`your question ${question.id.substr(0, 5) /*TODO make this a nice number*/}`}
        answerHeader={answerHeader}
        currentAnswer={answerText}
        updateValue={() => undefined}
        onPrevQuestion={prevQuestion}
        onNextQuestion={nextQuestion}
        onPrevAnswer={prevAnswer}
        onNextAnswer={nextAnswer}
        onModeChange={(mode: "question" | "answer" | "view") => {
          this.onModeChange(mode)
        }}
        onSubmit={() => undefined}
      />
    )
  }

  onModeChange(mode: "question" | "answer" | "view") {
    switch (mode) {
      case "answer":
        this.props.history.push("/questions")
        break;
      case "question":
        this.props.history.push("/")
        break;
      case "view":
        break;
    }
  }
}

export default QuestionPage;
