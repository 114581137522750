import React from 'react';
import '../App.css';
import { Calculator } from '../components/calculator-v2';
import {
  Answer,
  getAllQuestions,
  getMyAnswer,
  getQuestionById,
  Question,
  QuestionWithAnswer, updateMyAnswer
} from "../services/questions";
import { List } from "immutable";
import { APIResult } from "../types/generics";
import { useHistory } from "react-router-dom";
import { History } from "history";
import LoadingPage from "./Loading";

type AsyncState<T> = WaitingState | ErrorState | FulfilledState<T>;

interface WaitingState {
  mode: "waiting";
}

interface ErrorState {
  mode: "error";
  error: string;
}

interface FulfilledState<T> {
  mode: "fulfilled";
  value: T
}

type AnswerModeWrapperState = AsyncState<List<Question>>;

export default class AnswerModeWrapper extends React.Component<{}, AnswerModeWrapperState> {

  constructor(props: {}) {
    super(props)
    this.state = {
      mode: "waiting",
    };
  }

  componentDidMount() {
    // TODO paging?
    getAllQuestions()
      .then(this.handleApiResult)
      .catch(error => {
        console.error(error);
      });
  }

  handleApiResult = (apiResult: APIResult<List<Question>>) => {
    switch (apiResult.result) {
      case "failure":
        this.setState({
          mode: "error",
          error: apiResult.error,
        })
        break;
      case "success":
        this.setState({
          mode: "fulfilled",
          value: apiResult.value,
        });
        break;
    }
  };


  render() {
    switch (this.state.mode) {
      case "waiting":
        return this.renderWaiting(this.state);
      case "error":
        return this.renderFailure(this.state);
      case "fulfilled":
        return (
          <AnswerMode
            questions={this.state.value}
          />
        );
    }
  }

  renderWaiting(state: WaitingState) {
    return (
      <LoadingPage/>
    );
  }

  renderFailure(state: ErrorState) {
    return (
      <div>
        Failure: {state.error}
      </div>
    );
  }
}

interface AnswerModeProps {
  questions: List<Question>;
}

interface AnswerModeState {
  questionsIdx: number;
}

class AnswerMode extends React.Component<AnswerModeProps, AnswerModeState> {
  onPrevQuestion?: () => void;
  onNextQuestion?: () => void;

  constructor(props: AnswerModeProps) {
    super(props);
    this.state = {
      questionsIdx: 0,
    };
    this.updateListeners(0);
  }

  componentDidUpdate(prevProps: Readonly<AnswerModeProps>) {
    if (prevProps.questions !== this.props.questions) {
      this.setQuestionIdx(0);
    }
  }

  render() {
    const {questions} = this.props;
    const {questionsIdx} = this.state;
    const question = questions.get(questionsIdx)!; // TODO what if questionsIdx gets out of range?

    return (
      <AnsweringCalculatorWrapper
        question={question}
        onPrevQuestion={this.onPrevQuestion}
        onNextQuestion={this.onNextQuestion}/>
    )
  }

  private setQuestionIdx(newQuestionsIdx: number) {
    this.updateListeners(newQuestionsIdx);
    this.setState({questionsIdx: newQuestionsIdx});
  }

  private updateListeners(newQuestionsIdx: number) {
    this.onPrevQuestion = newQuestionsIdx > 0
      ? () => this.setQuestionIdx(newQuestionsIdx - 1)
      : undefined;
    this.onNextQuestion = newQuestionsIdx < this.props.questions.size - 1
      ? () => this.setQuestionIdx(newQuestionsIdx + 1)
      : undefined;
  }
}

interface AnsweringCalculatorWrapperProps {
  question: Question;
  onPrevQuestion?: () => void;
  onNextQuestion?: () => void;
}

type AnsweringCalculatorWrapperState = AsyncState<{
  answers: List<Answer>,
  myAnswer: Answer | undefined,
}>


class AnsweringCalculatorWrapper extends React.Component<AnsweringCalculatorWrapperProps, AnsweringCalculatorWrapperState> {

  constructor(props: AnsweringCalculatorWrapperProps) {
    super(props)
    this.state = {
      mode: "waiting",
    };
  }
  componentDidUpdate(prevProps: AnsweringCalculatorWrapperProps, prevState: AnsweringCalculatorWrapperState) {
    if(prevProps.question.id !== this.props.question.id) {
    Promise.all([
      getQuestionById(this.props.question.id),
      getMyAnswer(this.props.question.id),
    ])

      .then(([questionWithAnswer, myAnswer]) => this.handleApiResult(questionWithAnswer, myAnswer))
      .catch((err: Error) => {
        this.setState({
          mode: "error",
          error: err.message,
        });
      });

    }

  }
  componentDidMount() {
    Promise.all([
      getQuestionById(this.props.question.id),
      getMyAnswer(this.props.question.id),
    ])

      .then(([questionWithAnswer, myAnswer]) => this.handleApiResult(questionWithAnswer, myAnswer))
      .catch((err: Error) => {
        this.setState({
          mode: "error",
          error: err.message,
        });
      });
  }

  async handleApiResult(questionWithAnswer: APIResult<QuestionWithAnswer>, myAnswerResult: APIResult<Answer | undefined>) {
    if (myAnswerResult.result === "failure") {
      console.error(myAnswerResult.error);
      // don't panic!
    }

    switch (questionWithAnswer.result) {
      case "failure":
        this.setState({
          mode: "error",
          error: questionWithAnswer.error,
        })
        break;
      case "success":
        const myAnswer = myAnswerResult.result === "success" ? myAnswerResult.value : undefined;

        this.setState({
          mode: "fulfilled",
          value: {
            answers: questionWithAnswer.value.answers,
            myAnswer,
          },
        });
        break;
    }
  };


  render() {
    switch (this.state.mode) {
      case "waiting":
        return this.renderWaiting(this.state);
      case "error":
        return this.renderFailure(this.state);
      case "fulfilled":
        return <AnsweringCalculatorHistory
          question={this.props.question}
          onPrevQuestion={this.props.onPrevQuestion}
          onNextQuestion={this.props.onNextQuestion}
          answers={this.state.value.answers}
          myAnswer={this.state.value.myAnswer}
        />;
    }
  }

  renderWaiting(state: WaitingState) {
    return (
      <LoadingPage/>
    );
  }

  renderFailure(state: ErrorState) {
    return (
      <div>
        Failure: {state.error}
      </div>
    );
  }
}


interface AnsweringCalculatorProps extends AnsweringCalculatorWrapperProps {
  answers: List<Answer>;
  myAnswer: Answer | undefined;
  history: History
}

interface AnsweringCalculatorState {
  answersIdx: number;
  answerValue: string;
  thanks: boolean;
}

function AnsweringCalculatorHistory(props: Omit<AnsweringCalculatorProps, "history">) {
  const history = useHistory();
  return <AnsweringCalculator {...{...props, history}}/>;
}

class AnsweringCalculator extends React.Component<AnsweringCalculatorProps, AnsweringCalculatorState> {
  onPrevAnswer?: () => void;
  onNextAnswer?: () => void;

  constructor(props: AnsweringCalculatorProps) {
    super(props)
    this.state = {
      answersIdx: -1,
      answerValue: props.myAnswer?.data.text || "",
      thanks: false,
    };
    this.updateListeners(-1);
  }

  componentDidUpdate(prevProps: Readonly<AnsweringCalculatorProps>) {
    if (prevProps.question.id !== this.props.question.id) {
      this.setAnswerIdx(-1);
      this.setState({
        answerValue: this.props.myAnswer?.data.text || ""
      })
    }
  }

  render() {
    this.onPrevAnswer = this.state.answersIdx >= 0 // allow to go to -1 (which is own answer)
    ? () => this.setAnswerIdx(this.state.answersIdx - 1)
    : undefined;
  this.onNextAnswer = this.state.answersIdx < this.props.answers.size - 1
    ? () => this.setAnswerIdx(this.state.answersIdx + 1)
    : undefined;
    const {answers} = this.props;
    const {answersIdx, thanks} = this.state;
    const answer = answers.get(answersIdx)!; // TODO what if questionsIdx gets out of range?

    const mode: "answer" | "view" = thanks ? "view": answersIdx < 0 ? "answer" : "view"
    const answerHeader = thanks ? "THANK YOU" : answersIdx < 0 ? "YOUR ANSWER" : answer.name
    const currentAnswer = thanks ? "^_^" : answersIdx < 0 ? this.state.answerValue : answer.data.text

    return (
      <Calculator
        onModeChange={(mode: "question" | "answer" | "view") => {
          this.onModeChange(mode)
        }}
        mode={mode}
        currentQuestion={this.props.question.data.text}
        questionHeader={this.props.question.name}
        updateValue={(v, cb) => this.handleUpdate(v, cb)}
        editorValue={this.state.answerValue}
        currentAnswer={currentAnswer}
        answerHeader={answerHeader}
        onPrevQuestion={this.props.onPrevQuestion}
        onNextQuestion={this.props.onNextQuestion}
        onSubmit={() => this.handleSubmit()}
        onPrevAnswer={this.onPrevAnswer}
        onNextAnswer={this.onNextAnswer}
      />
    );
  }

  onModeChange(mode: "question" | "answer" | "view") {
    switch (mode){
      case "answer":
        this.props.history.push("/questions")
        break;
      case "question":
        this.props.history.push("/")
        break;
      case "view":
        break;
    }
  }

  handleUpdate(value: string, cb?: () => void) {
    if (this.state.answersIdx < 0) {
      this.setState({answerValue: value}, cb);
    }
  }

  handleSubmit() {
    if(this.state.answerValue !== "") {
      if (this.state.answersIdx < 0) {
        updateMyAnswer(this.props.question.id, {
          text: this.state.answerValue,
        })
          .then(() => {
            this.setState({thanks: true});
            setTimeout(() => {
              this.setState({thanks: false});
            }, 1000);
          })
      }
    }
  }

  private setAnswerIdx(newAnswersIdx: number) {
    this.updateListeners(newAnswersIdx);
    this.setState({answersIdx: newAnswersIdx});
  }

  private updateListeners(newAnswersIdx: number) {
    this.onPrevAnswer = newAnswersIdx >= 0 // allow to go to -1 (which is own answer)
      ? () => this.setAnswerIdx(newAnswersIdx - 1)
      : undefined;
    this.onNextAnswer = newAnswersIdx < this.props.answers.size - 1
      ? () => this.setAnswerIdx(newAnswersIdx + 1)
      : undefined;
  }
}
