export type APIResult<T> = NotExecutedApiResult | SuccessApiResult<T> | FailedAPIResponse

export type NotExecutedApiResult = { result: "waiting" }
export type SuccessApiResult<T> = {
    result: "success"
    value: T
}
export type FailedAPIResponse = {
    result: "failure"
    error: string
}

export const apiSuccess = <T>(value: T): SuccessApiResult<T> => ({result: "success", value: value})
export const apiFailure = (error: string): FailedAPIResponse => ({result: "failure", error: error})

export type Routing = { routing: false } | { routing: true, to: string }