import { List } from "immutable";
import { apiFailure, APIResult, apiSuccess } from "../types/generics";

type QuestionPostData = {
    text: string
}

type AnswerPostData = {
  text: string
}

type QuestionSubmitResponse = {
    id: string
}

type AnswerSubmitResponse = {
  id: string
}

export type Question = {
    id: string
    name: string
    data: {
        text: string
        created: Date
    }
}

export type Answer = {
    id: string
    name: string
    data: {
        text: string
        created: Date
    }
}

export type QuestionWithAnswer = Question & {
    answers: List<Answer>
}

export async function getAllQuestions(): Promise<APIResult<List<Question>>> {
    let allQuestionsResponse = await fetch("/api/questions")
    if (allQuestionsResponse.ok) {
        const questions: Question[] = await allQuestionsResponse.json()
        return apiSuccess(List(questions))
    } else {
        return apiFailure(allQuestionsResponse.statusText)
    }
}

export async function submitQuestion(question: QuestionPostData): Promise<APIResult<QuestionSubmitResponse>> {
    let response = await fetch("/api/questions", { method: "POST", body: JSON.stringify(question), headers: { "Content-Type": "application/json" } })
    if (response.ok) {
        const questionId: QuestionSubmitResponse = await response.json()
        return apiSuccess(questionId)
    } else {
        return apiFailure(response.statusText)
    }
}

export async function getQuestionById(id: string): Promise<APIResult<QuestionWithAnswer>> {
    let questionResponse = await fetch(`/api/questions/${id}`)
    if (questionResponse.ok) {
        const questionRaw = await questionResponse.json()
        let question = { ...questionRaw, answers: List(questionRaw.answers) }
        return apiSuccess(question)
    } else {
        return apiFailure(questionResponse.statusText)
    }
}

export async function getMyQuestions(): Promise<APIResult<List<Question>>> {
    let allQuestionsResponse = await fetch("/api/my-questions")
    if (allQuestionsResponse.ok) {
        const questions: Question[] = await allQuestionsResponse.json()
        return apiSuccess(List(questions))
    } else {
        return apiFailure(allQuestionsResponse.statusText)
    }
}

export async function getMyAnswer(qId: string): Promise<APIResult<Answer | undefined>> {
  const answerResponse = await fetch(`/api/questions/${qId}/my-answer`)
  if (answerResponse.ok) {
    const answerRaw = await answerResponse.json();
    const answer = answerRaw.id
      ? {
        id: answerRaw.id,
        data: answerRaw.data,
        name: "you",
      }
      : undefined;
    return apiSuccess(answer);
  } else {
    return apiFailure(answerResponse.statusText)
  }
}

export async function updateMyAnswer(qId: string, answer: AnswerPostData): Promise<APIResult<AnswerSubmitResponse>> {
  let response = await fetch(`/api/questions/${qId}/my-answer`, { method: "POST", body: JSON.stringify(answer), headers: { "Content-Type": "application/json" } })
  if (response.ok) {
    const answerId: AnswerSubmitResponse = await response.json()
    return apiSuccess(answerId)
  } else {
    return apiFailure(response.statusText)
  }
}
