import React, { createContext, useContext, useEffect, useState } from 'react';
import './calculater.css';
import { BrowserRouter as Router, Route, Switch, } from "react-router-dom";
import Home from "./pages/Home";
import QuestionPage from "./pages/Question";
import AnswerMode from "./pages/AnswerMode";
import { getMyIdentity, UserData } from "./services/identity";
import LoadingPage from "./pages/Loading";
import UpdateUserNamePage from "./pages/UpdateUserName";
import HomePage from './pages/Home';

export default function App() {
  return (
    <Router>
      <ProvideAuth>
        <div className="App">
          <Switch>
            <Route path="/settings">
              <UpdateUserNamePage/>
            </Route>
            <Route path="/questions/:qId">
              <QuestionPage/>
            </Route>
            <Route strict path="/questions">
              <AnswerMode/>
            </Route>
            <Route path="/">
              <HomePage/>
            </Route>
          </Switch>
        </div>
      </ProvideAuth>
    </Router>
  );
}

export const authContext = createContext<UserData | undefined>(undefined);

function ProvideAuth(props: { children: any }) {
  const [userData, fetchUserData] = useProvideAuth();
  if (!userData) {
    fetchUserData();
  }
  if (!userData?.name) {
    return (
      <UpdateUserNamePage/>
    );
  } else {
    return (
      <authContext.Provider value={userData}>
        {props.children}
      </authContext.Provider>
    );
  }
}

function useAuth() {
  return useContext(authContext);
}

function useProvideAuth(): [UserData | undefined, () => void] {
  const [userData, setUserData] = useState<UserData>();

  let requested = false;

  function fetchUserData() {
    // TODO vieze hack, hoe moet dit nou?
    if (requested) {
      return;
    }
    requested = true;
    console.log("fetching userData");
    getMyIdentity()
      .then(userData => {
        console.log("setting userdata", userData);
        setUserData(userData)
      })
      .catch(error => {
        console.error(error);
        throw error;
      });
  }

  return [
    userData,
    fetchUserData,
  ];
}
