import React, { useContext } from "react";
import { Keyboard } from "../components/calculator/keyboard";
import { Redirect } from "react-router-dom";
import { setUserName } from "../services/identity";
import { Calculator } from "../components/calculator-v2";
import { authContext } from "../App";
import {History} from "history";
import {useHistory} from "react-router-dom";

type UserNameState = {
  userName: string
}


export default function UpdateUserNamePage(props: {}) {
  const userData = useContext(authContext)
  const history = useHistory();
  return <UpdateUserNamePageContext userName={userData?.name} history={history}/>
}

interface UpdateUserNamePageContextProps {
  userName?: string;
  history: History;
}

class UpdateUserNamePageContext extends React.Component<UpdateUserNamePageContextProps, UserNameState> {
  constructor(props: UpdateUserNamePageContextProps) {
    super(props);
    this.state = {
      userName: props.userName || "",
    }
  }

  render() {
    return <Calculator
      onModeChange={(mode) => this.onModeChange(mode)}
      editorValue={this.state.userName}
      currentQuestion="^_^"
      questionHeader="WHAT IS YOUR NAME ?"
      answerHeader="MY NAME IS:"
      currentAnswer={this.state.userName}
      updateValue={(userName, cb) => this.setState({ userName }, cb)}
      onSubmit={() => this.submitHandler()}
      mode="answer"
    />
  }

  submitHandler() {
    if (this.state.userName !== "") {
      setUserName(this.state.userName)
        .then(() => {
          // hacky hacky!
          window.location.reload();
        })
    }

  }


  onModeChange(mode: "question" | "answer" | "view") {
    switch (mode){
      case "answer":
        this.props.history.push("/questions")
        break;
      case "question":
        this.props.history.push("/")
        break;
      case "view":
        break;
    }
  }

}
